<template>
    <div class="flex flex-col w-full justify-content-center pt-2">
        <hr />
        <h1 class="text-center py-2">Create Your Own Bingo Cards</h1>
        <div class="w-full py-2">
            <h2>What is My Bingo Maker?</h2>
            <div class="content">
                <ul>
                    <li>
                        My Bingo Maker is the fast, easy, and fun way to create bingo cards online. Easily add your words and
                        watch the bingo maker work its magic. Customize grid size, free spaces, the word at the top, colors,
                        and fonts. You can even add images for extra personalization.
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>What will my bingo set look like?</h2>
            <div class="content">
                <ul>
                    <li>
                        As you create your own bingo game, the finished product is displayed on the preview above. Once it’s
                        published, you’ll have a set of bingo cards and a Call List that you can print or download.
                    </li>
                </ul>
                <ul>
                    <li>
                        You can customize the word at the top, the number of squares, free spaces, and what appears on the
                        call list.
                    </li>
                </ul>
                <ul>
                    <li>Here are a few examples showing what’s possible:</li>
                </ul>
                <b-container fluid class="p-3 bg-carousel">
                    <b-carousel fade>
                        <b-carousel-slide v-for="(page, index) in carousel" :key="index">
                            <template #img>
                                <b-row>
                                    <b-col v-for="document in page" :key="document.thumb" class="text-center">
                                        <a :href="document.link" target="_blank">
                                            <img :src="document.thumb" width="150" height="193" alt="document example" />
                                        </a>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                </b-container>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>How many bingo cards do I get in a set?</h2>
            <div class="content">
                <ul>
                    <li>
                        You can have anywhere from 1 to 200 unique bingo cards in a single set. When setting up your cards,
                        you choose how many you want.
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>Why Choose My Bingo Maker?</h2>
            <div class="content">
                <ul>
                    <li>My Bingo Maker is the best online bingo card builder, and here’s why:</li>
                    <ul>
                        <li>Fully customizable bingo maker</li>
                        <li>Easy for first-time creators</li>
                        <li>No ads</li>
                        <li>Save-as-you-go editing</li>
                        <li>Access your bingo sets from any computer</li>
                        <li>Ideal for teachers</li>
                        <li>
                            Huge Community Library with over half a million
                            <a :href="faqLinks.bingoBrowse" target="_blank">free worksheets</a>
                            made by other educators
                        </li>
                        <li>Customer support from real humans (which we can afford because there are rarely issues)</li>
                    </ul>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>Do you have any other builders?</h2>
            <div class="content">
                <ul>
                    <li>Yes! We offer the following activity builders:</li>
                    <ul>
                        <li>
                            <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                            : The fast, easy, and fun way to make your own crossword puzzles
                        </li>
                        <li>
                            <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                            : Quickly create your own word searches with fun shapes and fonts
                        </li>
                        <li>
                            <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>
                            : Mix and match popular activity types for classroom printables
                        </li>
                        <li>
                            <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>
                            : Create custom bingo cards for class, game nights, and special events
                        </li>
                        <li>
                            <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                            : Make your own flashcards with helpful sizes and styles
                        </li>
                    </ul>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>What is BrightSprout?</h2>
            <div class="content">
                <ul>
                    <li>
                        BrightSprout offers the best activity makers online. It all started in 2013 with an easy online
                        crossword puzzle creator called
                        <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                        (formerly Crossword Hobbyist). Since then, we’ve added several more activity generators, starting
                        with
                        <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                        in 2015.
                        <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>
                        ,
                        <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>
                        , and
                        <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                        were launched a few years later in 2020. You can
                        <a :href="faqLinks.brightsprout" target="_blank">read more about BrightSprout</a>
                        on our Knowledge Base
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>What does My Bingo Maker cost?</h2>
            <div class="content">
                <ul>
                    <li>
                        A subscription to make unlimited bingo costs $5/month, or $4.25/month when billed annually. You can
                        try it free for 30 days before deciding if you want to subscribe. You can also purchase individual
                        bingo activities for special events.
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>Many things on the internet are free. Why not this?</h2>
            <div class="content">
                <ul>
                    <li>
                        In general, it’s possible to be the best or the cheapest but not both. We chose to be the best. We
                        respect that many people prefer the cheapest, and this may not be the site for them. But if you want
                        the best, you’ve come to the right place.
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>Can I keep my bingo sets private?</h2>
            <div class="content">
                <ul>
                    <li>
                        Yes. Whether you purchase an individual bingo activity or have a recurring plan, there is a checkbox
                        option on the publishing screen to save bingo sets as ‘private’ so only you can see them.
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2">
            <h2>Do I own the rights to my bingo set?</h2>
            <div class="content">
                <ul>
                    <li>
                        Yes, you do. We never use or sell your activities. You can read more about
                        <a :href="faqLinks.ownership" target="_blank">ownership and commercial use</a>
                        on our Knowledge Base.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { brightsproutUrls, faqLinks } from '../../config/Config'

export default defineComponent({
    name: 'BingoFaqSection',
    data() {
        return {
            carousel: [
                [
                    {
                        link: 'https://bingo.brightsprout.com/257666/musician-bingo-find-your-favorites',
                        thumb: '/images/musician_bingo_find_your_favorites.webp',
                    },
                    {
                        link: 'https://bingo.brightsprout.com/257662/tutti-frutti',
                        thumb: '/images/tutti_frutti.webp',
                    },
                    {
                        link: 'https://bingo.brightsprout.com/257658/all-about-whales-bingo',
                        thumb: '/images/all_about_whales_bingo.webp',
                    },
                ],
            ],
        }
    },
    computed: {
        brightsproutUrls() {
            return brightsproutUrls
        },
        faqLinks() {
            return faqLinks
        },
    },
})
</script>
