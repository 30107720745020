<template>
    <div class="items-wrapper">
        <div ref="itemsHolder" class="items-holder">
            <div v-if="showSortOptions" class="d-flex align-items-center justify-content-between mb-3">
                <StartOverButton @startOver="clearItems" />
                <template v-if="showDefinition() && showTerm()">
                    <b-button v-b-tooltip.hover class="mr-1" size="sm" title="Flip All" @click="swapAllItems()">
                        <b-icon-shuffle></b-icon-shuffle>
                    </b-button>

                    <b-form-select v-model="itemSort" size="sm" @change="sortItems">
                        <b-form-select-option :value="{ field: false, dir: false }">-- Sort By --</b-form-select-option>

                        <b-form-select-option :value="{ field: 'term', dir: 'asc' }">
                            {{ worksheet.type === 'word_scramble' ? 'Word A-Z' : $lang.get('sort_term_a_z') }}
                        </b-form-select-option>

                        <b-form-select-option :value="{ field: 'term', dir: 'desc' }">
                            {{ worksheet.type === 'word_scramble' ? 'Word Z-A' : $lang.get('sort_term_z_a') }}
                        </b-form-select-option>

                        <b-form-select-option :value="{ field: 'definition', dir: 'asc' }">
                            {{ worksheet.type === 'word_scramble' ? 'Clue A-Z' : $lang.get('sort_definition_a_z') }}
                        </b-form-select-option>

                        <b-form-select-option :value="{ field: 'definition', dir: 'desc' }">
                            {{ worksheet.type === 'word_scramble' ? 'Clue Z-A' : $lang.get('sort_definition_z_a') }}
                        </b-form-select-option>

                        <b-form-select-option :value="{ field: 'randomize' }">Random</b-form-select-option>
                    </b-form-select>
                </template>
            </div>

            <Draggable v-model="documentItems" v-bind="{ handle: '.item-handle' }">
                <b-card v-for="(item, index) in documentItems" :key="index" class="mb-2 position-relative" no-body>
                    <b-card-header class="py-0 px-1 d-flex justify-content-between align-items-center position-relative">
                        <div>
                            <a
                                :id="'item-handle-' + index"
                                v-b-tooltip.hover
                                href="#"
                                title="Reorder"
                                class="item-handle mr-2 text-secondary text-lg"
                                aria-label="drag"
                                tabindex="-1"
                                @click.prevent=""
                                @keyup.up="moveItemUp(index)"
                                @keyup.down="moveItemDown(index)"
                            >
                                <b-icon icon="grip-horizontal"></b-icon>
                            </a>
                        </div>
                        <!-- <div
                            :id="'customToolbar' + index"
                            class="custom-tools d-flex justify-content-between align-items-center border-0 position-absolute"
                        >
                            <button>
                                <b-icon icon="type-bold"></b-icon>
                            </button>
                            <button>
                                <b-icon icon="type-italic"></b-icon>
                            </button>
                            <button>
                                <b-icon icon="type-underline"></b-icon>
                            </button>
                        </div> -->
                        <a
                            v-b-tooltip.hover
                            href="#"
                            title="Remove Item"
                            class="text-danger text-lg"
                            aria-label="Delete"
                            tabindex="-1"
                            @click.prevent="removeItem(index)"
                        >
                            <b-icon icon="x-circle-fill"></b-icon>
                        </a>
                    </b-card-header>
                    <b-card-body class="p-1">
                        <FlashcardItemForm
                            :is="inputType"
                            v-if="inputType === 'flashcard-item-form'"
                            :ref="'flashcard-item-' + index"
                            :index="index"
                            @focus-next="handleFocusNext(index)"
                        />
                        <component :is="inputType" v-else-if="inputType" :index="index" />
                    </b-card-body>
                </b-card>
            </Draggable>
            <b-card v-if="showNewItemForm" no-body>
                <b-card-header></b-card-header>
                <b-card-body class="p-1">
                    <div class="mb-0 d-flex">
                        <VueEditor
                            :ref="'flashcard_front_word'"
                            v-model="itemTerm"
                            class="flashcard-input front hidden-header"
                            :editor-toolbar="customToolbar"
                            :editor-options="editorSettings"
                            placeholder="Front (optional)"
                            @input="handleNewAddInput"
                            @focus="focusNewFlashcard"
                        ></VueEditor>
                        <InlineImageBtn
                            btn-class="flashcard-inline-image-btn front"
                            :input-index="documentItems.length"
                            column="term"
                            @click="handleNewAddInput(`${documentItems.length}`)"
                        />
                    </div>
                    <div class="mb-0 d-flex">
                        <VueEditor
                            :ref="'flashcard_back_word'"
                            v-model="itemDefinition"
                            class="flashcard-input back hidden-header"
                            :editor-toolbar="customToolbar"
                            :editor-options="editorSettings"
                            placeholder="Back (optional)"
                            @input="handleNewAddInput"
                            @focus="focusNewFlashcard"
                        ></VueEditor>
                        <InlineImageBtn
                            :input-index="documentItems.length"
                            column="definition"
                            :btn-class="[{ 'border-top-0': hideBorder() }, 'flashcard-inline-image-btn back']"
                            @click="handleNewAddInput(`${documentItems.length}`)"
                        />
                    </div>
                </b-card-body>
            </b-card>

            <FakeAnswers v-if="worksheet.type === 'matching'" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import FakeAnswers from './FakeAnswers.vue'
import { mapGetters, mapState } from 'vuex'
import { copyObject } from '../helpers/copyObject'
import { VueEditor } from 'vue2-editor'
import InlineImageBtn from './AddInlineImageButton.vue'
import InlineImagesMixin from '../mixins/InlineImages'

// worksheet specific item forms
import WordScrambleItemForm from '../widgets/item-forms/word-scramble-item-form.vue'
import FillInTheBlankItemForm from '../widgets/item-forms/fill-in-the-blank-item-form.vue'
import MatchingItemForm from '../widgets/item-forms/matching-item-form.vue'
import MultipleChoiceItemForm from '../widgets/item-forms/multiple-choice-item-form.vue'
import HandwritingItemForm from '../widgets/item-forms/handwriting-item-form.vue'
import BingoItemForm from '../widgets/item-forms/bingo-item-form.vue'
import FlashcardItemForm from '../widgets/item-forms/flashcard-item-form.vue'
import OpenResponseItemForm from '../widgets/item-forms/open-response-item-form.vue'
import ChecklistItemForm from '../widgets/item-forms/checklist-item-form.vue'
import StartOverButton from './StartOverButton.vue'

export default defineComponent({
    name: 'ItemsForm',
    components: {
        StartOverButton,
        Draggable,
        FakeAnswers,
        FillInTheBlankItemForm,
        MatchingItemForm,
        HandwritingItemForm,
        BingoItemForm,
        WordScrambleItemForm,
        MultipleChoiceItemForm,
        FlashcardItemForm,
        OpenResponseItemForm,
        VueEditor,
        ChecklistItemForm,
        InlineImageBtn,
    },
    mixins: [InlineImagesMixin],
    props: {
        showSortOptions: {
            type: Boolean,
            // TODO: Check if we can set a default value. It is not set on the component where it is used.
            default: undefined,
        },
    },
    data() {
        return {
            justSorted: false,
            itemSort: { field: 'term', dir: 'asc' },
            lastElem: null,
            lastPos: 0,
            itemTerm: '',
            itemDefinition: '',
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: function () {
                                    if (self === window) return true
                                    self.onNextFocus()
                                    return false
                                },
                            },
                            tab: {
                                key: 9,
                                handler: function () {
                                    if (self === window) return true
                                    self.onNextFocus()
                                    return false
                                },
                            },
                        },
                    },
                },
            },
        }
    },
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            items: 'document/documentItems',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
        }),
        documentItems: {
            get() {
                return this.items
            },
            set(values) {
                // for resorting
                this.$store.dispatch('document/dragSortItems', values)
            },
        },
        inputType() {
            if (this.isWorksheet && this.worksheet.type) {
                return this.worksheet.type.replace(/_/g, '-') + '-item-form'
            } else if (!this.isWorksheet) {
                return this.document.entity_type + '-item-form'
            }
            return ''
        },
        buttonText() {
            if (this.worksheet.type === 'handwriting') {
                return 'Add new line'
            }

            if (this.isBingo) {
                return 'Add Word'
            }

            if (this.isFlashcard) {
                return 'Add New Card'
            }

            return 'Add new item'
        },
        showNewItemForm() {
            return (
                !this.documentItems.length ||
                (this.documentItems.length > 0 &&
                    (!this.isEmpty(this.documentItems[this.documentItems.length - 1].definition) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].term) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].term_image) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].definition_image)))
            )
        },
    },
    watch: {
        '$nav.active_panel': function (newPanel) {
            if (newPanel === 'body') {
                //non-reactive property
                this.tabIndex = false
                this.item_just_added = false
                this.resetItemSort()
                this.$refs['itemsHolder'].scrollTop = 0
            }
        },
    },
    created() {
        this.tabIndex = false
        this.item_just_added = false
        this.resetItemSort()
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.repeat) {
                return
            }

            if (e.key === 'Enter') {
                let isBingoFocused = Array.from(document.querySelectorAll('.bingo-input, .flashcard-input')).filter(
                    (element) => {
                        return document.activeElement === element
                    },
                ).length

                if (isBingoFocused) {
                    this.addNewItem()
                }
            }
        })
    },
    updated() {
        if (this.tabIndex) {
            if (this.showTerm()) {
                this.$refs['term'][this.tabIndex].$el.focus()
            } else {
                this.$refs['definition'][this.tabIndex].$el.focus()
            }

            this.tabIndex = false
        }
    },
    methods: {
        handleFocusNext(index) {
            const lastIndex = this.documentItems.length - 1
            if (lastIndex === index) {
                this.$refs['flashcard_front_word']?.quill.root.focus()
            } else {
                this.$refs['flashcard-item-' + (index + 1)][0]?.focusFrontWord()
            }
        },
        moveItemUp(index) {
            let items = copyObject(this.documentItems)
            let newIndex
            if (index) {
                newIndex = index - 1
            } else {
                newIndex = items.length - 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items
            this.$nextTick(() => {
                document.getElementById('item-handle-' + newIndex).focus()
            })
        },
        moveItemDown(index) {
            let items = copyObject(this.documentItems)

            let newIndex
            if (index === items.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items

            this.$nextTick(() => {
                document.getElementById('item-handle-' + newIndex).focus()
            })
        },
        getFocus(index) {
            return document.getElementById('worksheet-input-' + index)
        },
        setFocus(select) {
            let elem = this.getFocus(this.items.length - 1)

            if (elem) {
                elem.focus()
                elem.scrollIntoView()
                //select the text of the latest item on handwriting worksheets
                if (['handwriting', 'open_response'].includes(this.worksheet.type) && select) {
                    elem.select()
                }
            }

            this.$refs['flashcard-item-' + (this.items.length - 1)][0]?.focusFrontWord()
        },
        resetFocus() {
            if (this.$nav.active_panel === 'body') {
                let elem = this.getFocus(0)
                if (elem) {
                    elem.focus()
                    elem.blur()
                    document.getElementById('body-panel-content').scrollTop = 0
                }
            }
        },
        showDefinition() {
            return (
                !['word_search', 'handwriting', 'multiple_choice'].includes(this.worksheet.type) &&
                this.document.entity_type !== 'bingo'
            )
        },
        showTerm() {
            return !['open_response'].includes(this.worksheet.type)
        },
        sortItemList(field, direction) {
            this.$store.dispatch('document/sortItems', {
                field: field,
                direction: direction,
            })
        },
        resetItemSort() {
            this.itemSort = { field: false, dir: false }
        },
        sortItems() {
            if (this.itemSort.field) {
                if (this.itemSort.field === 'randomize') {
                    this.$store.dispatch('document/randomizeItems')
                } else {
                    this.sortItemList(this.itemSort.field, this.itemSort.dir)
                }

                this.justSorted = true
            }
        },
        tab(type, e, index) {
            if (index === this.items.length - 1) {
                if (
                    type === 'handwriting' ||
                    (type === 'definition' && this.showDefinition() && this.worksheet.type !== 'fill_in_the_blank') ||
                    (type === 'term' &&
                        this.showDefinition() === false &&
                        !['handwriting', 'multiple_choice'].includes(this.worksheet.type)) ||
                    (type === 'term' && this.worksheet.type === 'fill_in_the_blank')
                ) {
                    e.preventDefault()
                    this.tabIndex = index + 1
                    this.addNewItem()
                }
            }
        },
        addNewItem(data = null) {
            this.$store.dispatch('document/pushNewItem', data)

            this.$nextTick(function () {
                this.setFocus(true)
            })

            this.item_just_added = true
        },
        removeItem(index) {
            this.$store.dispatch('document/removeItem', index)
        },
        clearItems() {
            this.$store.dispatch('document/clearItems')
            this.$store.dispatch('document/clearFakeAnswers')
        },
        swapItem(index) {
            this.$store.dispatch('document/swapItem', index)
        },
        swapAllItems() {
            for (let i = 0; i < this.documentItems.length; i++) {
                this.swapItem(i)
            }
        },
        handleNewAddInput(value) {
            if (this.isEmpty(value)) return

            const term = this.itemTerm
            const definition = this.itemDefinition
            const definition_image = ''
            const term_image = ''
            const item = {
                term: term,
                definition: definition,
                definition_image: definition_image,
                term_image: term_image,
            }
            // if (this.itemTerm || this.itemDefinition) {
            this.addNewItem(item)
            // }
            if (this.itemTerm) {
                const quill = this.$refs.flashcard_front_word?.quill
                if (quill) {
                    quill.setContents([])
                    quill.setText('')
                }
            } else if (this.itemDefinition) {
                const quill = this.$refs.flashcard_back_word?.quill
                if (quill) {
                    quill.setContents([])
                    quill.setText('')
                }
            }
            this.$store
                .dispatch('document/updateItem', {
                    index: this.documentItems.length - 1,
                    item: {
                        term,
                        definition,
                        definition_image,
                        term_image,
                    },
                })
                .then(() => {
                    document.getElementById(`flashcard-front-${this.documentItems.length - 1}`)
                })
            this.itemTerm = ''
            this.itemDefinition = ''
        },
        isEmpty(value) {
            return value === null || value.trim().length === 0
        },
        focusNewFlashcard() {
            let elem = document.getElementById('workspaceContainer')
            elem.scrollIntoView({ block: 'center', behavior: 'smooth' })
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.custom-tools {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.items-wrapper {
    $button-size: 22px;

    .item-wrapper {
        position: relative;
        border-radius: $border-radius;
        position: relative;
        margin-bottom: 15px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

        .group-vertical {
            border-radius: $border-radius;
        }

        .item-wrapper-group {
            padding: 10px;
            border: 1px solid $gray-300;
        }

        .item-addon-section {
            background-color: $gray-400;

            padding: 10px;
            border: 1px solid $gray-400;
            border-top: 0;
            border-bottom-width: 0px;

            &.last {
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, $gray-400 100%);
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                border-bottom-width: 1px;
            }
        }
        .multiple-choice-options {
            .item-addon-section {
                background-color: $gray-400;
                background-image: none;
                border-top: 0;
                border-bottom: 0;
                border-radius: 0;
                padding-bottom: 0px;
            }
        }
    }

    .items-holder {
        flex-grow: 1;

        .item-handle {
            cursor: hand;
            cursor: grab;
        }
    }
    .sortable-chosen,
    .sortable-ghost {
        .item-handle {
            cursor: hand !important;
            cursor: grabbing !important;
        }
    }
    .swap-container {
        position: relative;
        overflow: visible;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 1.3em;
        height: 0px;
    }
}
</style>
