<template>
    <div class="flex flex-column w-full">
        <div>
            <p class="text-muted f-11 mb-0 mt-3 font-weight-bold">LEFT-SIDE FORMAT</p>
            <div class="flex flex-column w-full">
                <NestedSelect
                    :default-option="leftColumnFormat"
                    :options="numberFormatOptions"
                    :show-premium="showPremium"
                    @change="(key) => columnFormatChange(key, 'left')"
                />
            </div>
        </div>
        <div>
            <p class="text-muted f-11 mb-0 mt-3 font-weight-bold">RIGHT-SIDE FORMAT</p>
            <div class="flex flex-column w-full">
                <NestedSelect
                    :default-option="rightColumnFormat"
                    :options="numberFormatOptions"
                    :show-premium="showPremium"
                    is-secondary
                    @change="(key) => columnFormatChange(key, 'right')"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NestedSelect from '../../../stories/components/form/NestedSelect.vue'
import { numberingFormats } from '../../../objects/Document'
import { FormatOption, FormatSubOption } from '../../../stories/types/FormatOption'
import { find } from 'lodash'

interface Data {
    leftColumnFormat: FormatOption | FormatSubOption
    rightColumnFormat: FormatOption | FormatSubOption
    numberFormatOptions: FormatOption[]
}
export default defineComponent({
    name: 'MatchingPreviewFormat',
    components: { NestedSelect },
    props: {
        showPremium: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: -1,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    data(): Data {
        return {
            leftColumnFormat: {} as FormatOption,
            rightColumnFormat: {} as FormatOption,
            numberFormatOptions: numberingFormats as FormatOption[],
        }
    },
    watch: {
        item: {
            handler() {
                this.leftColumnFormat = this.setSelected('left')
                this.rightColumnFormat = this.setSelected('right')
            },
            immediate: true,
        },
    },
    methods: {
        async columnFormatChange(format: number, column: 'left' | 'right') {
            this.$emit('click')
            await this.$store.dispatch('document/setMatchingColumnFormat', { index: this.index, at: column, format: format })
            await this.$store.dispatch('document/storeDocumentState')
            if (column === 'left') {
                this.leftColumnFormat = this.setSelected(column)
                return
            }
            this.rightColumnFormat = this.setSelected(column)
        },
        setSelected(column: 'left' | 'right'): FormatOption | FormatSubOption {
            const columnKey = column === 'left' ? 'left_column_format' : 'right_column_format'
            const hasFormat = this.item?.settings?.[columnKey]

            for (const option of this.numberFormatOptions) {
                if (!hasFormat) continue
                if (option.value === this.item.settings[columnKey]) return option

                if (option.subOptions) {
                    const subOption = find(
                        option.subOptions,
                        (subOption) => parseInt(subOption.value) === this.item.settings[columnKey],
                    )
                    if (subOption) return subOption
                }
            }

            return column === 'left'
                ? {
                      label: '1.',
                      value: '2',
                      isPremium: false,
                  }
                : {
                      label: 'A.',
                      value: '11',
                      isPremium: false,
                  }
        },
    },
})
</script>
