<template>
    <div>
        <div class="mb-0 d-flex">
            <VueEditor
                :key="`prompt_${index}`"
                :ref="`prompt_${index}`"
                v-model="subtitleInput"
                class="open-response-input prompt"
                :class="{
                    'hidden-header': focusedEditor !== frontQuill,
                    'br-half': data.has_answer,
                    'br-full': !data.has_answer,
                }"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Write your prompt here."
                @focus="getPromptFocus"
                @input="handlePromptInput"
            />
        </div>
        <div class="mb-0 d-flex">
            <VueEditor
                v-show="data.has_answer"
                :key="`answer_${index}`"
                :ref="`answer_${index}`"
                v-model="answerInput"
                class="open-response-input answer br-half"
                :class="{
                    'hidden-header': focusedEditor !== backQuill,
                }"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Include an answer (optional)"
                @focus="getAnswerFocus"
                @input="handleAnswerInput"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { stripHtmlTags } from '../../helpers/stringUtils'

export default defineComponent({
    name: 'OpenResponseItemForm',
    components: {
        VueEditor,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        var self = this
        return {
            itemData: {
                ...this.data,
            },
            frontQuill: undefined,
            backQuill: undefined,
            focusedEditor: undefined,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: function () {
                                    self.onNextFocus()
                                    return false
                                },
                            },
                            tab: {
                                key: 9,
                                handler: function () {
                                    self.onNextFocus()
                                    return false
                                },
                            },
                        },
                    },
                },
            },
        }
    },
    computed: {
        subtitleInput: {
            get() {
                return this.itemData.subtitle
            },
            set(value) {
                this.itemData.subtitle = value
            },
        },
        answerInput: {
            get() {
                return this.itemData.answer_type === 'handwriting'
                    ? !this.itemData.handwritingAnswer
                        ? stripHtmlTags(this.itemData.answer)
                        : this.itemData.handwritingAnswer
                    : this.itemData.answer
            },
            set(value) {
                this.itemData.handwritingAnswer = stripHtmlTags(value)
                this.itemData.answer = value
            },
        },
    },
    watch: {
        'data.has_answer': {
            handler(newValue) {
                if (newValue) {
                    this.backQuill = this.$refs[`answer_${this.index}`]?.quill
                    this.getAnswerFocus()
                    if (this.backQuill) {
                        this.backQuill.setSelection(0, this.backQuill.getLength())
                    }
                } else {
                    this.getPromptFocus()
                }
            },
            immediate: true,
        },
        'data.answer_type': {
            handler(newValue) {
                this.itemData.answer_type = newValue
            },
            immediate: true,
        },
    },
    mounted() {
        this.frontQuill = this.$refs[`prompt_${this.index}`]?.quill
        this.backQuill = this.$refs[`answer_${this.index}`]?.quill
        this.focusedEditor = this.frontQuill
    },
    methods: {
        updateData() {
            this.$emit('input', this.itemData)
        },
        onNextFocus() {
            if (this.focusedEditor === this.frontQuill) {
                this.backQuill?.focus()
            } else if (this.focusedEditor === this.backQuill) {
                this.$emit('focus-next')
            }
        },
        getPromptFocus() {
            this.focusedEditor = this.frontQuill
            this.$nextTick(() => this.frontQuill?.focus())
        },
        getAnswerFocus() {
            this.focusedEditor = this.backQuill
            this.$nextTick(() => this.backQuill?.focus())
        },
        handlePromptInput() {
            this.updateData()
            this.getPromptFocus()
        },
        handleAnswerInput() {
            this.updateData()
            this.getAnswerFocus()
        },
    },
})
</script>

<style lang="scss">
.open-response-input {
    width: 100%;

    .ql-toolbar.ql-snow {
        position: absolute;
        top: 0;
        left: 45px;
        transform: translateX(-50%);
        border: none;
        padding: 0 !important;

        .ql-formats {
            margin-right: 0;

            button {
                outline: none !important;
            }
        }
    }

    &.hidden-header {
        .ql-toolbar.ql-snow {
            display: none;
        }
    }

    &.prompt .ql-container {
        border-top: 1px solid #ccc !important;
        border-top-left-radius: 0.25rem;
    }

    &.answer .ql-container {
        border-top: 0 !important;
        border-bottom-left-radius: 0.25rem;
    }

    &.br-full.prompt .ql-container {
        border-radius: 0.25rem;
    }

    &.br-half.prompt .ql-container {
        border-radius: 0.25rem 0.25rem 0 0;
    }

    &.br-half.answer .ql-container {
        border-radius: 0 0 0.25rem 0.25rem;
    }
}
</style>
