<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <VueEditor
                :ref="`widget_${index}`"
                v-model="instructionInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Write your instructions here."
                @text-change="updateData"
            ></VueEditor>
        </div>

        <AdvancedOptionsToggleButton :active="showAdvancedOptions" @click="showAdvancedOptions = !showAdvancedOptions" />

        <div v-if="showAdvancedOptions">
            <div style="display: none">
                <label class="font-weight-bold mb-1 f-11 text-muted">Choose Instruction Template</label>
                <b-form-select
                    v-model="selected"
                    label="Body Font"
                    :options="options"
                    size="sm"
                    class="mt-0"
                    @change="populateInstruction"
                ></b-form-select>
            </div>

            <div class="row">
                <div class="col-md-9">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Border</label>
                    <div class="d-flex f-10 justify-content-between mb-0">
                        <div>Thin</div>
                        <div>Thick</div>
                    </div>
                    <b-form-input
                        v-model="itemStyleData.border_width"
                        type="range"
                        min="0"
                        max="8"
                        @change="updateData"
                    ></b-form-input>
                </div>

                <div class="col-md-3">
                    <ColorPicker v-model="itemStyleData.border_color" label="Color" @change="updateData" />
                </div>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted mt-2">Border Style</label>
                <div class="d-flex row">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="groove"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label groove w-100"></div>
                            <span class="f-11 text-muted text-height-1">Groove</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="dashed"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label dashed w-100"></div>
                            <span class="f-11 text-muted text-height-1">Dashed</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="solid"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label solid w-100"></div>
                            <span class="f-11 text-muted text-height-1">Solid</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="outset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label outset w-100"></div>
                            <span class="f-11 text-muted text-height-1">Outset</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2 row">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="dotted"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label dotted w-100"></div>
                            <span class="f-11 text-muted text-height-1">Dotted</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="double"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label double w-100"></div>
                            <span class="f-11 text-muted text-height-1">Double</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="ridge"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label ridge w-100"></div>
                            <span class="f-11 text-muted text-height-1">Ridge</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemStyleData.border_style"
                            name="border-style"
                            value="inset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label inset w-100"></div>
                            <span class="f-11 text-muted text-height-1">Inset</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import ColorPicker from '../ColorPicker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'

export default defineComponent({
    name: 'InstructionBox',
    components: {
        ColorPicker,
        VueEditor,
        AdvancedOptionsToggleButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        itemStyle: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            itemStyleData: {
                ...this.itemStyle,
            },
            selected: 0,
            options: [
                { value: 0, text: 'Custom Instructions' },
                { value: 1, text: 'Open Response Instructions' },
                { value: 2, text: 'Multiple Choice Instructions' },
                { value: 3, text: 'Handwriting Instructions' },
                { value: 4, text: 'Matching Instructions' },
                { value: 5, text: 'Fill in the Blank Instructions' },
                { value: 6, text: 'Word Scramble Instructions' },
            ],
            showAdvancedOptions: false,
            instructions: [
                '',
                'Answer each question in the space provided.',
                'Circle the correct answer for each question below.',
                'Use your best handwriting to write each word below.',
                'Match the word on the left side to its definition on the right side.',
                'Fill in the blank with the missing word.',
                'Unscramble each word and write it in the blank provided.',
            ],
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        instructionInput: {
            get() {
                return this.itemData.instruction
            },
            set(value) {
                this.itemData.instruction = value
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`]?.quill.focus()
    },
    methods: {
        populateInstruction(value) {
            this.itemData.instruction = this.instructions[value]
            this.updateData()
        },
        updateData() {
            this.$emit('change', this.itemData)
            this.$emit('update:itemStyle', this.itemStyleData)
        },
    },
})
</script>
