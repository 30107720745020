<template>
    <div class="card-body pb-1 pt-0">
        <div class="d-flex align-items-center">
            <div class="w-75 pr-4">
                <label class="font-weight-bold d-flex py-1 justify-content-between mb-0 f-11 text-muted">
                    <span>Height</span>
                    <span class="font-weight-normal">{{ Math.round(itemData.height * 10) / 10 }} in</span>
                </label>

                <b-form-input v-model="lineHeight" type="range" :min="0.125" :max="8.3" :step="0.01"></b-form-input>
            </div>

            <div class="w-25">
                <label class="font-weight-bold mb-0 f-11 text-muted">Box Outline</label>
                <b-form-checkbox
                    v-model="boxOutline"
                    class="f-14 text-muted ml-1 mr-n2 pb-3"
                    tabindex="-1"
                    name="box_outline"
                    switch
                ></b-form-checkbox>
            </div>
        </div>

        <AdvancedOptionsToggleButton
            v-if="itemData.box_outline"
            class="mt-2"
            :active="showOptions"
            @click="showOptions = !showOptions"
        />

        <div v-if="itemData.box_outline && showOptions">
            <div class="d-flex mt-2 align-items-baseline">
                <div class="flex-grow-1 mr-4">
                    <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold">Border</div>
                    </label>
                    <div class="d-flex f-10 justify-content-between mb-0">
                        <div>Thin</div>
                        <div>Thick</div>
                    </div>

                    <b-form-input
                        v-model="itemData.border_width"
                        type="range"
                        :min="1"
                        :max="10"
                        :step="1"
                        @change="updateData"
                    ></b-form-input>
                </div>

                <div class="colorpicker-wrapper">
                    <ColorPicker v-model="itemData.line_color" label="Color" @change="updateData" />
                </div>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted">Border Style</label>
                <div class="d-flex row">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="groove"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label groove w-100"></div>
                            <span class="f-11 text-muted text-height-1">Groove</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="dashed"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label dashed w-100"></div>
                            <span class="f-11 text-muted text-height-1">Dashed</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="solid"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label solid w-100"></div>
                            <span class="f-11 text-muted text-height-1">Solid</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="outset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label outset w-100"></div>
                            <span class="f-11 text-muted text-height-1">Outset</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex row mt-2">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="dotted"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label dotted w-100"></div>
                            <span class="f-11 text-muted text-height-1">Dotted</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="double"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label double w-100"></div>
                            <span class="f-11 text-muted text-height-1">Double</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="ridge"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label ridge w-100"></div>
                            <span class="f-11 text-muted text-height-1">Ridge</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="inset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="d-flex flex-column">
                            <div class="border-line-label inset w-100"></div>
                            <span class="f-11 text-muted text-height-1">Inset</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ColorPicker from '../ColorPicker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'

export default defineComponent({
    name: 'BlankSpace',
    components: { ColorPicker, AdvancedOptionsToggleButton },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
            showOptions: false,
        }
    },
    computed: {
        lineHeight: {
            get() {
                return this.itemData.height
            },
            set(value) {
                this.itemData.height = value
                this.updateData()
            },
        },
        boxOutline: {
            get() {
                return this.itemData.box_outline
            },
            set(value) {
                this.itemData.box_outline = value
                this.updateData()
                if (!value) {
                    this.showOptions = value
                }
            },
        },
    },
    methods: {
        updateData() {
            this.closedAdvanced = !this.closedAdvanced
            this.$emit('change', this.itemData)
        },
        closeOptions() {
            this.closedAdvanced = !this.closedAdvanced
        },
    },
})
</script>
