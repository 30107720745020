<template>
    <svg width="34" height="14" viewBox="0 0 34 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13H33.4921M0 7H33.4921M0 1H33.4921" stroke="black" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconStandardLines',
})
</script>
