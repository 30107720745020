<template>
    <div class="btn-toggle-settings text-center mt-1">
        <IconButton
            class="px-2"
            icon="Gear"
            size="sm"
            :variant="active ? 'primary' : 'outline-primary'"
            :disabled="disabled"
            @click="handleClick"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IconButton from '../../stories/components/buttons/IconButton.vue'

export default defineComponent({
    name: 'AdvancedOptionsToggleButton',
    components: { IconButton },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click')
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.btn-toggle-settings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    z-index: 1;

    &:before,
    &:after {
        content: ' ';
        width: 50px;
        height: 1px;
        background-color: $gray-500;
    }
}
</style>
